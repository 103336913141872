import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { CollectionService } from '../../../services/collection.service';
import { ProductCollection } from '../../../classes/productcollection';

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.css']
})

export class CollectionsComponent implements OnInit {

  currentCollection: ProductCollection;
  currentBrowseCollections: ProductCollection[];  
  indooroutdoor: string;
  collectiongroupheader: string;
  sub:any;
  group:string;
  constructor(private route: ActivatedRoute,
    private collectionService: CollectionService,
    private location: Location) { }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {this.group = params['indooroutdoor'];
    console.log(this.group);
      if (this.group) {
        this.getCollectionsByIndoorOutdoor(this.group);
        this.collectiongroupheader = this.group + " Product Collections"
    } else {
        this.getAllCollections();
        this.collectiongroupheader = "Product Collections"
    }
  });
  }

getAllCollections(): void {
  this.collectionService.getAllCollections()
    .subscribe(currentBrowseCollections => this.currentBrowseCollections = currentBrowseCollections);
}

getCollectionsByIndoorOutdoor(indooroutdoor:string): void {
  this.collectionService.getCollectionsByIndoorOutdoor(indooroutdoor)
    .subscribe(currentBrowseCollections => this.currentBrowseCollections = currentBrowseCollections);
} 

goBack(): void {
  this.location.back();
}

}
