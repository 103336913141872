export class Product {
    Option_SKU_ID: number;
    Option_SKU: string;
    Collection_Name: string;
    HTML_Dimensions: string;
    HMTL_Options: string;
    HTML_Includes: string;
    Shape: string;
    Copy_Paragraph: string;
    Assembly_Required: string;
    Option_Description: string;
    Out_of_Stock: string;
    Collection_ID: number;
    Option_Name: string;
    Master_SKU_ID: number;
    Product_Category_1: string;
    Product_Category_2: string;
    Product_Category_3: string;
    Days_Ago_Created: number;
    Net_Sell_Price: number;
    Host_Thumbnail_Photo_Path: string;
    Host_Full_Name_Photo_Path: string;
    Host_Web_Size_Photo_Path: string;
    SKU_Search_String: string;
    Indoor_Outdoor: string;
    Product_Category_1_Name: string;
    Product_Category_2_Name: string;
    Product_Category_3_Name: string;
    Material_1: string;
    Material_2: string;
    Material_3: string;
    Material_4: string;
    Material_5: string;
  }