import { Injectable } from '@angular/core';
import { Observable, throwError  } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Product } from '../classes/product';
import { map, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ProductService {

  ProductDetail: Product;
  BrowseProducts: Product[]; 
  productOptions: Product[]; 
  productphotos: Product[];
  baseUrl = 'https://www.internationalcaravan.com/api';


  constructor(private http: HttpClient) { }

  getProductsBySearchString(searchterms:string): Observable<Product[]> {
    return this.http.get(this.baseUrl+'/getProductsbySearch.php?searchterms='+searchterms).pipe(
      map((res) => {
        this.BrowseProducts = res['data'];
        return this.BrowseProducts;
      }),
      catchError(this.handleError));
  }

  getProductsByCollectionID(collectionid:number): Observable<Product[]> {
    return this.http.get(this.baseUrl+'/getProductsbyCollectionID.php?collectionid='+collectionid).pipe(
      map((res) => {
        this.BrowseProducts = res['data'];
        return this.BrowseProducts;
      }),
      catchError(this.handleError));
  }

  getProductsByCategoryandIndoorOutdoor(categoryid:number,indooroutdoor:string): Observable<Product[]> {
    return this.http.get(this.baseUrl+'/getProductsbyCategoryIDandIndoorOutdoor.php?categoryid='+categoryid+'&indooroutdoor='+indooroutdoor).pipe(
      map((res) => {
        this.BrowseProducts = res['data'];
        return this.BrowseProducts;
      }),
      catchError(this.handleError));
  }

  getProductDetailsbyID(optionskuid:number): Observable<Product> {
    return this.http.get(this.baseUrl+'/getProductDetailsbyID.php?optionskuid=' + optionskuid).pipe(
      map((res) => {
        this.ProductDetail = res['data'];
        console.log(this.ProductDetail);
        return this.ProductDetail;
      }),
      catchError(this.handleError)); 
  }

  getProductsByNewArrivals(): Observable<Product[]> {
    return this.http.get(this.baseUrl+'/getProductsByNewArrivals.php').pipe(
      map((res) => {
        this.BrowseProducts = res['data'];
        return this.BrowseProducts;
      }),
      catchError(this.handleError)); 
  }

  getProductOptionPhotosbyOptionSKUID(optionskuid:number): Observable<Product[]> {
    return this.http.get(this.baseUrl+'/getProductOptionPhotosbyOptionSKUID.php?optionskuid='+optionskuid).pipe(
      map((res) => {
        this.productphotos = res['data'];
        console.log(this.productphotos);
        return this.productphotos;
      }),
      catchError(this.handleError)); 
  }

  getProductOptionsbyMasterSKUID(masterskuid:number): Observable<Product[]> {
    return this.http.get(this.baseUrl+'/getProductOptionsbyMasterSKUID.php?masterskuid='+masterskuid).pipe(
      map((res) => {
        this.productOptions = res['data'];
        console.log(this.productOptions);
        return this.productOptions;
      }),
      catchError(this.handleError)); 
  }

  

  private handleError(error: HttpErrorResponse) {
    console.log(error);
    // return an observable with a user friendly message
    return throwError('Error! something went wrong.');
  }



}
