<div class="container mx-auto">
    <div class="row" style="max-width:75rem;margin:auto">
        <div class="col-xs-12">
            <img class="img-fluid" src="assets/Website banner Clear.jpg" alt="" style="margin:auto"/>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <h2 class="col-md-12" style="text-align:center; margin: 1rem">Browse by Category</h2>
        </div>
    </div>
    <div class="container">
        <div class="row" style="max-width:60rem; margin:auto ">
            <nav>
            <ul class="nav nav-pills nav-fill mx-auto mb-3" style="margin-bottom:0.625rem;"  role="tablist">
              <li class="nav-item col-xs-6 col-sm-6 " style="height:100%"><a class="nav-link active" [ngClass]='{"active":activetog==1}' (click)="activeCategoryGroup = 'Outdoor';activetog=1" >Outdoor Categories</a></li>
              <li class="nav-item col-xs-6 col-sm-6" style="height:100%"><a class="nav-link active"  [ngClass]='{"active":activetog==2}' (click)="activeCategoryGroup = 'Indoor';activetog=2" >Indoor Categories</a></li>
            </ul>
            <div class="card-deck" *ngIf="activeCategoryGroup === 'Outdoor'" >
                <div class="col-xs-6 col-sm-3 px-0" *ngFor="let category of outdoorCategories | orderBy: 'Product_Category'"> 
                    <div class="card" class="grow" >
                        <a class="link-unstyled" href="#/browseproducts/bycategory/{{category.indoor_outdoor}}/{{category.product_category.split(' ').join('_')}}/{{category.product_category_id}}">
                            <img class="card-img-top img-fluid" src="{{category.top_seller_photo_path}}" onerror="this.src='assets/productimagenotavailable.png'">
                            <div class="card-body">
                                <h4 class="card-title">{{category.product_category}}</h4>
                            </div>
                        </a>
                      </div>
                </div>
            </div>
            <div class="card-deck" *ngIf="activeCategoryGroup === 'Indoor'" >
                <div class="col-xs-6 col-sm-3 px-0" *ngFor="let category of indoorCategories | orderBy: 'Product_Category'"> 
                    <div class="card" class="grow" >
                        <a class="link-unstyled" href="#/browseproducts/bycategory/{{category.indoor_outdoor}}/{{category.product_category.split(' ').join('_')}}/{{category.product_category_id}}">
                            <img class="card-img-top img-fluid" src="{{category.top_seller_photo_path}}" onerror="this.src='assets/productimagenotavailable.png'">
                            <div class="card-body">
                                <h4 class="card-title">{{category.product_category}}</h4>
                            </div>
                        </a>
                      </div>
                </div>
            </div>
        </nav>
        </div>
    </div>
    </div>