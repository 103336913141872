import { Injectable } from '@angular/core';
import { Observable, throwError  } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ProductCollection } from '../classes/productcollection';
import { map, catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class CollectionService {

  currentCollection: ProductCollection;
  currentBrowseCollections: ProductCollection[];  
  baseUrl = 'https://www.internationalcaravan.com/api';
  constructor(private http: HttpClient) { }


  getAllCollections(): Observable<ProductCollection[]> {
    return this.http.get(this.baseUrl+'/getallcollections.php').pipe(
      map((res) => {
        this.currentBrowseCollections = res['data'];
        return this.currentBrowseCollections;
      }),
      catchError(this.handleError));
  }

  getCollectionsByIndoorOutdoor(indooroutdoor:string): Observable<ProductCollection[]> {
    return this.http.get(this.baseUrl+'/getcollectionsbyindooroutdoor.php?indooroutdoor=' + indooroutdoor).pipe(
      map((res) => {
        this.currentBrowseCollections = res['data'];
        console.log(this.currentBrowseCollections);
        return this.currentBrowseCollections;
      }),
      catchError(this.handleError)); 
  }

  private handleError(error: HttpErrorResponse) {
    console.log(error);
    // return an observable with a user friendly message
    return throwError('Error! something went wrong.');
  }


}
