
import { Component, OnInit } from '@angular/core';
import { CollectionService } from '../../services/collection.service';
import { ProductCollection } from '../../classes/productcollection';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  allCollections: ProductCollection[];  
  indoorCollections: ProductCollection[];  
  outdoorCollections: ProductCollection[];  

  searchterms: string;
  searchstring: string;
  searchurl:string;
  collectiongroupheader: string;

  constructor(private collectionService: CollectionService) { }

  ngOnInit(): void {
    this.getIndoorCollections();
    this.getOutdoorCollections();
  }

  getAllCollections(): void {
    this.collectionService.getAllCollections()
      .subscribe(allCollections => this.allCollections = allCollections);
  }

  getIndoorCollections(): void {
    this.collectionService.getCollectionsByIndoorOutdoor("Indoor")
      .subscribe(indoorCollections => this.indoorCollections = indoorCollections);
  }

  getOutdoorCollections(): void {
    this.collectionService.getCollectionsByIndoorOutdoor("Outdoor")
      .subscribe(outdoorCollections => this.outdoorCollections = outdoorCollections);
  }
  

}
