import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/views/home/home.component';
import { ContactusComponent } from './components/views/contactus/contactus.component';
import { OurstaffComponent } from './components/views/ourstaff/ourstaff.component';
import { OurpartnersComponent } from './components/views/ourpartners/ourpartners.component';
import { CollectionsComponent } from './components/views/collections/collections.component';
import { BrowseproductsComponent } from './components/views/browseproducts/browseproducts.component';
import { AboutusComponent } from './components/views/aboutus/aboutus.component';
import { ProductdetailsComponent } from './components/views/productdetails/productdetails.component';

const routes: Routes = [
  {path:  "", pathMatch:  "full",redirectTo:  "home"},
  {path: "home", component: HomeComponent},
  {path: "aboutus", component: AboutusComponent},
  {path: "ourstaff", component: OurstaffComponent},
  {path: "ourpartners", component: OurpartnersComponent},
  {path: "contactus", component: ContactusComponent},  
  {path: "collections/:indooroutdoor", component: CollectionsComponent,runGuardsAndResolvers: 'paramsOrQueryParamsChange' },
  {path: "browseproducts", component: BrowseproductsComponent}, 
  {path: "browseproducts/bycollection/:collectionname/:collectionid", component: BrowseproductsComponent,runGuardsAndResolvers: 'paramsOrQueryParamsChange' },  
  {path: "browseproducts/bycategory/:indooroutdoor/:categoryname/:categoryid", component: BrowseproductsComponent,runGuardsAndResolvers: 'paramsOrQueryParamsChange' },
  {path: "browseproducts/search/:searchstring", component: BrowseproductsComponent,runGuardsAndResolvers: 'paramsOrQueryParamsChange' },
  {path: "browseproducts/newarrivals/:newarrivals", component: BrowseproductsComponent,runGuardsAndResolvers: 'paramsOrQueryParamsChange' },
  {path: "browseproducts/productdetail/:optionskuid", component: ProductdetailsComponent,runGuardsAndResolvers: 'paramsOrQueryParamsChange' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ onSameUrlNavigation: 'ignore' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
