<div class="container" style="max-width:800px">
    <div class="row">
        <h1 class="mx-auto" style="border-bottom:1px solid grey;">Our E-Retail Partners</h1>
    </div>
    <div class="row" style="margin-bottom:25px">
        <div class="col-6">
            <div style="margin:auto">
                <a href="http://www.overstock.com/search?refinebrand=International+Caravan&TID=internationalcaravanSL" target="_blank">
                    <img class="img-fluid"src="assets/ourpartners/overstock.jpg" style="max-height:200px">
                </a>
            </div>
        </div>
        <div class="col-6">
            <div style="margin:auto">
                <a href="http://www.wayfair.com/International-Caravan-B2952.html" target="_blank">
                    <img class="img-fluid partnerimage mx-auto d-block" src="assets/ourpartners/wayfair.jpg" style="max-height:200px;margin:auto">
                </a>
            </div>
        </div>
        <div class="col-6">
            <div style="margin:auto">
                <a href="http://www.cymax.com/international-caravan--C0.htm?q=international-caravan" target="_blank">
                    <img class="img-fluid partnerimage mx-auto d-block" src="assets/ourpartners/cymax.jpg"  style="max-height:200px;margin:auto">
                </a>
            </div>
        </div>
        <div class="col-6">
            <div style="margin:auto">
                <a   href="http://www.hayneedle.com/brands/international-caravan/list&source=googleaw&kwid=international%20caravan&tid=phrase&redirect=false?gclid=CNvn_-Kc_coCFdgDgQodq_cLLg" target="_blank">
                <img class="img-fluid partnerimage mx-auto d-block" src="assets/ourpartners/hayneedle.jpg" style="max-height:200px;margin:auto">
                </a>
            </div>
        </div>
        <div class="col-6">
            <div style="margin:auto">
                <a href="http://www.brookstone.com/index"  target="_blank">
                    <img class="img-fluid partnerimage mx-auto d-block" src="assets/ourpartners/brookstone.png"  style="max-height:200px;margin:auto">
                </a>
            </div>
        </div>
        <div class="col-6">
            <div style="margin:auto">
                <a href="http://www.shopladder.com/International-Caravan-BR469.htm?rd=1" target="_blank">
                    <img class="img-fluid partnerimage mx-auto d-block" src="assets/ourpartners/ivg.jpg"  style="max-height:200px;margin:auto">
                </a>
            </div>
        </div>
        <div class="col-6">
            <div style="margin:auto">
                <a  href="http://www.target.com/s?searchTerm=international+caravan&category=0|All|matchallpartial|all+categories&lnk=snav_sbox_international+caravan"  target="_blank">
                    <img class="img-fluid partnerimage mx-auto d-block" src="assets/ourpartners/target.jpeg" style="max-height:200px;margin:auto">
                </a>
            </div>
        </div>
        <div class="col-6">
            <div style="margin:auto">
                <a href="https://www.atgstores.com/international-caravan_m1281.html" target="_blank">
                    <img class="img-fluid partnerimage mx-auto d-block" src="assets/ourpartners/lowes.png"  style="max-height:200px;margin:auto">
                </a>
            </div>
        </div>
        <div class="col-6">
            <div style="margin:auto">
                <a href="https://www.wayfair.ca/International-Caravan-B2952.html" target="_blank">
                    <img class="img-fluid partnerimage mx-auto d-block" src="assets/ourpartners/wayfair-ca-logo.png"  style="max-height:200px;margin:auto">
                </a>
            </div>
        </div>
    </div>
</div>