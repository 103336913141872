import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ProductService } from '../../../services/product.service';
import { Product } from '../../../classes/product';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-browseproducts',
  templateUrl: './browseproducts.component.html',
  styleUrls: ['./browseproducts.component.css']
})
export class BrowseproductsComponent implements OnInit {

  sub:any;
  browseProducts: Product[];
  browseproductsheader:string;
  indooroutdoor:string;
  searchstring:string;
  collectionid:number;
  collectionname:string;
  categoryid:number;
  categoryname:string;
  newarrivals:string;
  page = 1;
  product: Product;
  collectionSize:number;
  pageSize:number;
  maxSize:number ;
  rotate:string ;
  boundaryLinks:string;

  constructor(private router: Router,private route: ActivatedRoute,
    private productService: ProductService,
    private location: Location) { 

      this.product = new Product();
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      if (params['indooroutdoor'] && params['categoryid']) {
        this.indooroutdoor = params['indooroutdoor'];
        this.categoryid = params['categoryid'];
        this.categoryname = params['categoryname'];
        this.getProductsByCategoryandIndoorOutdoor(this.categoryid,this.indooroutdoor);
        this.browseproductsheader = this.indooroutdoor + " " + this.categoryname.split('_').join(' ');
      } else if (params['searchstring']) {
        this.searchstring = params['searchstring'];
        console.log('good');
        this.getProductsBySearchString(this.searchstring);
        this.browseproductsheader = "Search results: " + this.searchstring.split('_').join(' ');
      } else if (params['collectionid']) {
        this.collectionid = params['collectionid'];
        this.collectionname = params['collectionname'].split('_').join(' ');
        this.getProductsByCollectionID(this.collectionid);
        this.browseproductsheader = this.collectionname + " " + "Collection";
      } else if (params['newarrivals']) {
        this.newarrivals = params['newarrivals'];
        this.getProductsByNewArrivals();
        this.browseproductsheader = "New Arrivals"
      } else {

      }



  });
  }

  getProductsBySearchString(searchstring:string): void {
    this.productService.getProductsBySearchString(searchstring)
      .subscribe(BrowseProducts => this.browseProducts = BrowseProducts);
  }
  
  getProductsByCollectionID(collectionid:number): void {
    this.productService.getProductsByCollectionID(collectionid)
      .subscribe(BrowseProducts => this.browseProducts = BrowseProducts);
  } 

  getProductsByCategoryandIndoorOutdoor(categoryid:number,indooroutdoor:string): void {
    this.productService.getProductsByCategoryandIndoorOutdoor(categoryid,indooroutdoor)
      .subscribe(BrowseProducts => this.browseProducts = BrowseProducts);
  } 

  getProductsByNewArrivals(): void {
    this.productService.getProductsByNewArrivals()
      .subscribe(BrowseProducts => this.browseProducts = BrowseProducts);
  } 
  
  goBack(): void {
    this.location.back();
  }
  
  ngOnDestroy() {
    // ...
  }

}
