<div class="container mx-auto">
    <div class="row">
        <h1 class="col-12 mx-auto" style="text-align:center; margin:1rem">{{collectiongroupheader}}</h1>
    </div>
    <div class="row">
        <div class="card-deck">
            <div class="col-6 col-sm-4 col-md-3 col-lg-3 px-0" *ngFor="let collection of currentBrowseCollections  | orderBy: 'Collection_Name' "> 
                <div class="card" class="thumbnail grow" >
                    <a class="link-unstyled" href="#/browseproducts/bycollection/{{collection.Collection_Name.split(' ').join('_')}}/{{collection.Collection_ID}}">
                        <img class="card-img-top img-fluid" src="{{collection.Top_Seller_Photo_Path}}" onerror="this.src='assets/productimagenotavailable.png'">
                        <div class="card-body">
                            <h4 class="card-title">{{collection.Collection_Name}} Collection</h4>
                        </div>
                    </a>
                  </div>
            </div>
        </div>
    </div>
</div>