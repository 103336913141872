import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ProductService } from '../../../services/product.service';
import { Product } from '../../../classes/product';
import { ProductPhoto } from '../../../classes/productphoto';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-productdetails',
  templateUrl: './productdetails.component.html',
  styleUrls: ['./productdetails.component.css']
})
export class ProductdetailsComponent implements OnInit {

  productdetail:Product;
  productoptions:Product[];
  productphotos:ProductPhoto[];
  sub:any;
  optionskuid:number;
  numoptionphotos:number;
  optionPhotosBarWidth:string;
  numproductoptions:number;
  productOptionBarWidth:string;
  html:string;
  description:string;
  currentImage:string;
  currentImagePinPath:string;
  productoptionsub:Subscription;
  productphotossub:Subscription;
  productdetailsub:Subscription;

  constructor(private router: Router,private route: ActivatedRoute,
    private productService: ProductService,
    private location: Location) { 
      this.productdetail = new Product();
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      if (params['optionskuid']) {
        this.optionskuid = params['optionskuid'];
      } else {
      }
    });
    this.getProductDetailsbyID(this.optionskuid);
  }


  getProductDetailsbyID(optionskuid:number): void {
    this.productdetailsub = 
    this.productService.getProductDetailsbyID(optionskuid)
      .subscribe(ProductDetail => {
        this.productdetail = ProductDetail
        this.getProductOptionsbyMasterSKUID(this.productdetail.Master_SKU_ID);
        this.getProductOptionPhotosbyOptionSKUID(this.productdetail.Option_SKU_ID);

        this.html = (this.productdetail.HTML_Includes || '') + (this.productdetail.HMTL_Options || '') + (this.productdetail.HTML_Dimensions || '');
        this.description = this.html;
    
        this.currentImage = this.productdetail.Host_Web_Size_Photo_Path;
        this.currentImagePinPath = 'http://ecaravan.net' + this.productdetail.Host_Web_Size_Photo_Path.substring(1);


      });
  }
  getProductOptionsbyMasterSKUID(masterskuid:number): void {
    this.productoptionsub = 
    this.productService.getProductOptionsbyMasterSKUID(masterskuid)
      .subscribe(productOptions => {
        this.productoptions = productOptions
        this.numproductoptions = this.productoptions.length;	
        console.log(this.numproductoptions);
        this.productOptionBarWidth = (this.numproductoptions * 7.5) + 'rem';
      });
  }
  
  getProductOptionPhotosbyOptionSKUID(optionskuid:number): void {
    this.productphotossub = 
    this.productService.getProductOptionPhotosbyOptionSKUID(optionskuid)
      .subscribe(productphotos => {
        this.productphotos = productphotos;
      
        this.numoptionphotos = this.productphotos.length;	
        console.log(this.numoptionphotos);
        this.optionPhotosBarWidth = (this.numoptionphotos * 6.875) + 'rem';});
  }

  setNewImage(newPhotoPath){
    this.currentImage = newPhotoPath;
  }

  
ngOnDestroy() {
  if (this.productoptionsub) {this.productoptionsub.unsubscribe();}
  if (this.productphotossub) {this.productphotossub.unsubscribe();}
  if (this.productdetailsub) {this.productdetailsub.unsubscribe();}
}


  sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }
  
}
