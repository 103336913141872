<footer>
    <div class="container-fluid">
        <div class="row sitefooter">
            <i style="margin:auto">Copyright 2016, International Caravan, Inc.</i>
            <script>(function(d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = "//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.6";
                fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'facebook-jssdk'));</script>
            <div class="fb-like" data-href="https://www.facebook.com/InternationalCaravanCorporation/?fref=ts" data-layout="button_count" data-action="like" data-show-faces="false" data-share="false" style="float:right"></div>
        </div>
    </div>
 </footer>