<div id="fb-root"></div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <a class="navbar-brand flex" href="#">
        <div class="d-flex">
          <img src="assets/ICI_Logo.png" width="50" height="50" class="d-block align-top" alt="">
          <h3 class="d-none d-md-inline d-lg-none" style="margin-left: 1em;margin-top:0.5rem; height:100%">International Caravan Home Furnishings</h3>
          <h3 class="d-none d-sm-inline d-md-none" style="margin-left: 1em;margin-top:0.5rem; height:100%">International Caravan Home</h3>
          <h3 class="d-inline d-sm-none" style="margin-left: 1em;margin-top:0.5rem; height:100%">International Caravan</h3>
        </div>
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
         <!-- <li class="nav-item active">
            <a class="nav-link" href="#/home">Home <span class="sr-only">(current)</span></a>
          </li> -->
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Product Collections
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown"  style="width:200%;min-width:31.25px;border-top-left-radius:0;border-top-right-radius:0px;margin:0">
              <a class="dropdown-item" href="#/collections/Outdoor">Outdoor Furnishing Collections</a>
              <a  class="dropdown-item" href="#/collections/Indoor">Indoor Furnishing Collections</a>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#/ourpartners">E-Retail Partners</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              About Us
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown"  style="width:200%;min-width:31.25px;border-top-left-radius:0;border-top-right-radius:0px;margin:0">
              <a class="dropdown-item" href="#/aboutus">Who We Are</a>
              <a class="dropdown-item" href="#/contactus">Contact Us</a>
            </div>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="http://www.blazingneedleslp.com" style="color:#EC5734"><img src="assets/BNfavicon-32x32.png" width="20" height="20" alt="">Blazing Needles</a>
          </li>
        </ul>
          <form class="form-inline my-2 my-lg-0">
            <div class="input-group">
              <input class="form-control" [(ngModel)]="searchstring" name="search" type="search" placeholder="Search our catalog..." aria-label="Search" style="width:16rem">
              <div class="input-group-append">
              <button class="btn btn-info my-2 my-sm-0" type="submit" ><a style="color: inherit;" href="#/browseproducts/search/{{searchstring.split(' ').join('_')}}">Search</a></button>
            </div>
            </div>
          </form>
      </div>
    </nav>