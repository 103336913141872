<div class="container" style="max-width:600px">
    <div class="row">
        <h2 style="border-bottom:1px solid grey;text-align:center">Our Staff</h2>
    </div>
    <div class="row">
        <h3 style="text-align:center">Coming Soon!</h3>
    </div>         
    <!--<div class="row">
        <div class="col-sm-6 col-xs-12 staffthumbnail">
                <img class="img-responsive staffimage"src="./images/ourstaff/hashaban.jpg">
            <div style="padding:auto">
                <h2>H.A. Shaban</h2>
                <h3>Owner, Founder</h3>
            </div>
        </div>
        <div class="col-sm-6 col-xs-12 staffthumbnail">
                <img class="img-responsive staffimage"src="./images/ourstaff/davidshaban.jpg">
            <div style="padding:auto">
                <h2>David Shaban</h2>
                <h3>General Manager, Buyer</h3>
            </div>
        </div>
        <div class="col-sm-6 col-xs-12 staffthumbnail">
                <img class="img-responsive staffimage" src="./images/ourstaff/alishaban.jpg">
            <div style="padding:auto">
                <h2>Ali Shaban</h2>
                <h3>Ecommerce Vendor Relations</h3>
            </div>
        </div>
        <div class="col-sm-6 col-xs-12 staffthumbnail">
                <img class="img-responsive staffimage"src="./images/ourstaff/bryanshaban.jpg">
            <div style="padding:auto">
                <h2>Bryan Shaban</h2>
                <h3>Head of Information Technology, Product Development Specialist</h3>
            </div>
        </div>
        <div class="col-sm-6 col-xs-12 staffthumbnail">
                <img class="img-responsive staffimage"src="./images/ourstaff/gaylynnmilner.jpg">
            <div style="padding:auto">
                <h2>Gaylynn Milner</h2>
                <h3>Shipping Manager</h3>
            </div>
        </div>
    </div>-->
</div>