<div class="container-fluid" style="margin:auto;max-width:1080px;font-size:16px;padding:3em">
    <br>
    <div class="row">
        <blockquote>
        <h3>At International Caravan, Inc., we are dedicated to the quick and accurate order fulfillment of stylish , quality home furnishings to our eCommerce partners' customers and to our wholesale partners' stores.  Furthermore, we are committed to quality in our manufacturing processes, professionalism in our business dealings, and courtesy in our customer relations.  <img src="assets/favicon-32x32.png" style="display:inline;vertical-align:middle" width="20" height="20" alt=""/></h3>
            <footer>International Caravan Management</footer>
        </blockquote>
    </div>
    <div class="row">
        <img class="img-fluid mx-auto col-sm-6 col-xs-12" style="padding-right:1em;float:left;clear:right;" src="assets/Beach Mills Aerial View_LR_EDIT.jpg">
        <div>
            <h2 style="border-bottom:1px solid grey;padding:5px;">Who We Are</h2>
            <p>	&nbsp;&nbsp;&nbsp;&nbsp;International Caravan, Inc. is an importer/ wholesaler specializing in eCommerce drop-shipping of indoor and outdoor home furnishings and décor.</p>
            <p>	&nbsp;&nbsp;&nbsp;&nbsp;We have many years of experience in both the domestic and imported textile industries, with suppliers stretching across the Southeast Asia. Our purchasing team has a long history of business dealings in the import home furnishings market. Our shipping department has years of experience in providing accurate LTL and Fedex/UPS drop-ship parcel shipping service on behalf of our many wholesale and eRetail partners. We have a well-earned reputation in the domestic home furnishings and décor market as a wholesaler/drop-shipper operating with the utmost honesty, quality, and efficacy, with a long track record of trusting relationships with our suppliers and customers.</p>
            <p>	&nbsp;&nbsp;&nbsp;&nbsp;With a wholesale catalog of over 1,600 unique products spanning a wide variety of home furnishing categories, International Caravan is rapidly becoming one of the most diverse and prevalent home furnishing and decor brands in the burgeoning eCommerce marketplace.  Our catalog features products from across the market spectrum of mass-market home furnishings, with large selections of both indoor and outdoor-ready furnishings.</p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;While our products are currently only available for wholesale or third-party eCommerce drop-ship purchase, our products are readily available for purchase online with one of our many eRetail and wholesale partners, including Overstock, Wayfair, Hayneedle, Target, Lowes, and At Home.
            </p>
        </div>
        <div>
            <h2 style="border-bottom:1px solid grey;padding:5px;margin-top:5px;">Our Story</h2>
            <p>	&nbsp;&nbsp;&nbsp;&nbsp;International Caravan Inc. (ICI) is a family-owned and operated importing, wholesale, and drop-shipping company based in Fort Worth, Texas and founded in 1967 by H.A. Shaban. Upon its creation, International Caravan initially dealt with ceramic products imported from two company-owned factories in Mexico. Later, during the early-to-mid 70s, ICI moved its operating facilities to the Harry Hines Business district of Dallas, Texas and began to deal more in the importation of baskets from East-Asian countries such as Thailand and the Philippines. By the mid-eighties, ICI began extensive importation from countries such as Taiwan, Hong Kong, and Indonesia, importing primarily floral products and some pieces of promotional furniture.</p>
            <p>	&nbsp;&nbsp;&nbsp;&nbsp;In the early-80s, ICI again moved its operating facilities, this time to the Wayne Feeds Mill complex in historic East Fort Worth. In the early 90s, ICI began to shift its product focus more into the realm of home décor and home furnishings. In the mid-90s, ICI purchased an office building (which has since been recognized as a historic building) right next to the Wayne Feeds Mill building; to this day, this office building remains ICI’s primary administrative office.  Around this time, ICI constructed a new warehouse adjacent to this administrative office to accommodate its ever-expanding product line.</p>
            <p>	&nbsp;&nbsp;&nbsp;&nbsp;As the new millennium dawned, ICI shifted its product focus, this time to adjust to the growing mail-order and eCommerce market. ICI’s product began to consist heavily of outdoor furniture that could be “knocked down” and shipped directly to the customer; however, ICI still to this day maintains some of the early home décor product lines that characterized the company’s humble beginnings.</p>
            <p>	&nbsp;&nbsp;&nbsp;&nbsp;Today, International Caravan stands as a leader in the burgeoning home furnishings eCommerce market, its clientele ranging from multi-million dollar internet retailers to small mom-and-pop décor businesses, its suppliers stretching from Indonesia to China. Above all, ICI prides itself in the responsibility with which it conducts business, the loyalty it shows its clients and its employees, and the satisfaction of every customer who purchases one of our products.		        	</p>
        </div>
    </div>
</div>
    